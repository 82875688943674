




















































































































import { Component, Vue } from "vue-property-decorator";
import Form from "@/components/Form.vue";

@Component({
  components: { Form }
})
export default class Home extends Vue {
  private features = [
    {
      name: "Configure your room (specify dimensions and environmental conditions for calculation)",
      light: true,
      pro: true
    },
    {
      name: "Specify absorption and scattering materials using the reverberate wall partition editor.",
      light: true,
      pro: true
    },
    {
      name: "Inspect the results and compare the reverberation time calculated for multiple frequencies",
      light: true,
      pro: true
    },
    {
      name: "Check your room against different DIN 18041 usage scenarios",
      light: true,
      pro: true
    },
    {
      name: "Download your results as PDF",
      light: false,
      pro: true
    },
    {
      name: "Add and edit materials for your calculations",
      light: false,
      pro: true
    },
    {
      name: "Save your calculations",
      light: false,
      pro: true
    },
    {
      name: "Import custom materials",
      light: false,
      pro: true
    },
    {
      name: "Import material packages provided by reverberate",
      light: false,
      pro: true
    }
  ];
  openNewsletter() {
    setTimeout(
      () =>
        (this.$refs.newsletter as HTMLElement).scrollIntoView({
          block: "start",
          behavior: "smooth"
        }),
      100
    );
    (this.$refs.newsletter as HTMLElement).style.visibility = "visible";
    (this.$refs.newsletter as HTMLElement).style.maxHeight = "900px";
    (this.$refs.welcome as HTMLElement).style.maxHeight = "0px";
  }
}
