import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
//import Home from "../views/Home.vue";

//import Success from "@/pages/Success.vue";
//import Optin from "@/pages/Optin.vue";
import Home from "@/pages/Home.vue";
/*import Pro from "@/pages/Pro.vue";
import Pro_Demo from "@/pages/Pro_Demo.vue";

import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";
*/
Vue.use(VueRouter);

/** Default page title that will appear in browser tab and bookmarks. Will be extended by the routes below. */
const DEFAULT_TITLE = "reverberate";

/**
 * It's part of the concept that many routes use the same path. This is because not
 * all routes should create a history entry that will be accessible for users via url.
 * Note that the order of the routes array is important, because the first matched path
 * will be the matched route!
 * To link to a specific route internally the app uses programmatic navigation via
 * the name of the routes, e.g. {name: Results}
 * Navigation guards inside the routes guarantee that results page is only shown, if
 * a valid calculation result is in store.
 */
const routes: Array<RouteConfig> = [
  {
    path: "",
    name: "Home",
    component: Home,
    meta: {
      title: `Welcome to reverberate`
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.meta?.scrollBelowHeader) {
      console.log("scroll below header");
      console.log(document.querySelector("#pageTop"));
      return { selector: "#pageTop" };
    }
    // scroll to top on navigation. see https://router.vuejs.org/guide/advanced/scroll-behavior.html
    return { x: 0, y: 0 };
  }
});

type titleFunction = (to: Route) => string;

/** Update page title */
router.afterEach(to => {
  let title = "";
  if (to.meta?.title) {
    if (typeof to.meta.title === "function") {
      title = (<titleFunction>to.meta.title)(to);
    } else if (typeof to.meta.title === "string") {
      title = to.meta.title;
    }
  }
  document.title = DEFAULT_TITLE;
  if (title) {
    document.title = title;
  }
});

export default router;
